@font-face {
font-family: '__gtHaptik_e763ce';
src: url(/_next/static/media/a1ec6390a8dc5299-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gtHaptik_e763ce';
src: url(/_next/static/media/72ae5a352572ba4d-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__gtHaptik_Fallback_e763ce';src: local("Arial");ascent-override: 96.09%;descent-override: 23.90%;line-gap-override: 0.00%;size-adjust: 104.59%
}.__className_e763ce {font-family: '__gtHaptik_e763ce', '__gtHaptik_Fallback_e763ce'
}

